import dayjs from 'dayjs';
import { IFormSchema } from 'src/components/Form';
import {
  answerTimeItems,
  GradePublishMode,
  gradeTimeItems,
  screenMonitorTimes,
  SolutionPublishMode,
  VideoIntervalMap,
} from 'src/constants/exam';

export const schema: IFormSchema = {
  baseInfo: {
    type: 'void',
    'x-component': 'FormCard',
    'x-component-props': {
      title: '基本信息',
      className: 'm-4 mb-0',
      classNames: {
        body: 'w-full xs:w-full md:w-full sm:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/3 mx-auto',
      },
    },
    properties: {
      examName: {
        type: 'string',
        title: '考试名称',
        'x-component': 'Input',
        'x-component-props': {
          placeholder: '请输入考试名称',
        },
        'x-decorator': 'FormItem',
        'x-validator': [{ required: true, message: '请输入考试名称' }],
      },
      '[startTime, endTime]': {
        type: 'array',
        title: '考试时间',
        'x-component': 'DatePicker.RangePicker',
        'x-component-props': {
          showTime: true,
          minDate: dayjs(),
          placeholde: ['请选择考试开始时间', '请选择考试结束时间'],
        },
        'x-decorator': 'FormItem',
        'x-validator': [{ required: true, message: '请选择考试时间' }],
      },
      count: {
        type: 'number',
        title: '考试次数',
        'x-component': 'NumberPicker',
        'x-component-props': {
          min: 1,
          addonAfter: '次',
          precision: 0,
          step: 1,
          style: {
            width: '80%',
          },
          placeholder: '请输入考试次数',
        },
        'x-decorator': 'FormItem',
        'x-validator': [
          { required: true, message: '请输入考试次数' },
          { min: 1, message: '考试次数至少为一次' },
        ],
      },
      // 成绩公布类型
      gradePublish: {
        type: 'number',
        title: '成绩公布',
        'x-component': 'Radio.Group',
        'x-component-props': {
          options: gradeTimeItems,
        },
        'x-decorator': 'FormItem',
        default: GradePublishMode.PublishAfterSubmit,
        'x-validator': [{ required: true, message: '请选择成绩公布' }],
      },
      gradePublishTime: {
        type: 'string',
        title: '成绩公布时间',
        'x-component': 'DatePicker',
        'x-component-props': {
          showTime: true,
          placeholder: '请选择成绩公布时间',
        },
        'x-decorator': 'FormItem',
        'x-validator': [{ required: true, message: '请选择成绩公布时间' }],
        'x-reactions': {
          dependencies: ['.gradePublish'],
          fulfill: {
            schema: {
              'x-visible': '{{ $deps[0] === 3 }}',
            },
          },
        },
      },
      // 答案公布类型
      answerPublish: {
        type: 'number',
        title: '答案与解析',
        'x-component': 'Radio.Group',
        'x-component-props': {
          options: answerTimeItems,
        },
        'x-decorator': 'FormItem',
        default: SolutionPublishMode.NoPublish,
        'x-validator': [{ required: true, message: '请选择答案与解析' }],
      },
      answerPublishTime: {
        type: 'string',
        title: '答案公布时间',
        'x-component': 'DatePicker',
        'x-component-props': {
          showTime: true,
          placeholder: '请选择答案公布时间',
        },
        'x-decorator': 'FormItem',
        'x-validator': [{ required: true, message: '请选择答案公布时间' }],
        'x-reactions': {
          dependencies: ['.answerPublish'],
          fulfill: {
            schema: {
              'x-visible': '{{ $deps[0] === 2 }}',
            },
          },
        },
      },
      videoMonitor: {
        type: 'number',
        title: '是否开启视频监控',
        'x-component': 'Radio.Group',
        'x-component-props': {
          options: [
            { label: '否', value: 0 },
            { label: '是', value: 1 },
          ],
        },
        default: 0,
        'x-decorator': 'FormItem',
        'x-validator': [{ required: true, message: '请选择是否开启视频监控' }],
      },
      videoMonitorCount: {
        type: 'number',
        title: '抓拍次数',
        'x-component': 'Select',
        'x-component-props': {
          options: VideoIntervalMap,
          placeholder: '请选择抓拍次数',
        },
        default: 30,
        'x-decorator': 'FormItem',
        'x-decorator-props': {
          tooltip: '多久时间抓拍一次',
        },
        'x-validator': [{ required: true, message: '请选择抓拍次数' }],
        'x-reactions': {
          dependencies: ['.videoMonitor'],
          fulfill: {
            schema: {
              'x-visible': '{{ $deps[0] === 1 }}',
            },
          },
        },
      },
      screenMonitor: {
        type: 'number',
        title: '是否开启切屏监控',
        'x-component': 'Radio.Group',
        'x-component-props': {
          options: [
            { label: '否', value: 0 },
            { label: '是', value: 1 },
          ],
        },
        default: 0,
        'x-decorator': 'FormItem',
        'x-validator': [{ required: true, message: '请选择是否开启切屏监控' }],
      },
      screenMonitorCount: {
        type: 'number',
        title: '切屏次数',
        'x-component': 'Select',
        'x-component-props': {
          options: screenMonitorTimes,
          placeholder: '请选择切屏次数',
        },
        'x-decorator': 'FormItem',
        'x-decorator-props': {
          tooltip: '允许切屏次数',
        },
        'x-validator': [{ required: true, message: '请选择切屏次数' }],
        'x-reactions': {
          dependencies: ['.screenMonitor'],
          fulfill: {
            schema: {
              'x-visible': '{{ $deps[0] === 1 }}',
            },
          },
        },
      },
    },
  },
  paperInfo: {
    type: 'void',
    'x-component': 'FormCard',
    'x-component-props': {
      title: '试卷配置',
      className: 'mx-4',
      classNames: {
        body: 'w-full xs:w-full md:w-full sm:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/3 mx-auto',
      },
    },
    properties: {
      paperInfo: {
        type: 'object',
        'x-component': 'PaperSelect',
        'x-validator': [{ required: true, message: '请选择试卷' }],
      },
      passGrade: {
        type: 'number',
        title: '通过分数',
        'x-component': 'NumberPicker',
        'x-component-props': {
          addonAfter: '分',
          placeholder: '请输入通过分数',
        },
        'x-decorator': 'FormItem',
        'x-validator': [{ required: true, message: '请输入通过分数' }],
      },
    },
  },
  peopleInfo: {
    type: 'void',
    'x-component': 'FormCard',
    'x-component-props': {
      title: '人员配置',
      className: 'mx-4',
      classNames: {
        body: 'w-full xs:w-full md:w-full sm:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/3 mx-auto',
      },
    },
    properties: {
      studentType: {
        type: 'number',
        title: '考试范围',
        'x-component': 'Radio.Group',
        'x-component-props': {
          options: [
            { label: '个人', value: 1 },
            { label: '部门', value: 2 },
          ],
        },
        'x-decorator': 'FormItem',
        'x-validator': [{ required: true, message: '请选择考试范围' }],
      },
      studentSelect: {
        type: 'array',
        title: '个人',
        'x-component': 'Select',
        'x-component-props': {
          placeholder: '请选择个人',
        },
        'x-decorator': 'FormItem',
        'x-validator': [{ required: true, message: '请选择个人' }],
      },
    },
  },
};
