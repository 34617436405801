import { useRef } from 'react';
import { Button, Modal } from 'antd';
import PageHeaderBar from 'src/components/PageHeaderBar';
import { getParam } from 'src/utils/tools';
import { useNavigate } from 'react-router-dom';
import { Form, FormRef } from 'src/components/Form';
import { schema } from './schema';
import { Input, DatePicker, NumberPicker, Radio, Select } from '@formily/antd-v5';
import { useMemoizedFn } from 'ahooks';
import { onFormMount } from '@formily/core';
import { FormCard } from './components/FormCard';
import { PaperSelect } from './components/Paper';

export default function Exam() {
  const navigate = useNavigate();
  const formRef = useRef<FormRef | null>(null);
  const urlQuery = getParam(window.location.search);
  const examId = urlQuery?.id;
  const onBack = () => {
    navigate('/exam');
  };
  const handleBack = useMemoizedFn(() => {
    Modal.confirm({
      title: '提示',
      content: '当前数据还未保存，确定要关闭页面吗？',
      okText: '关闭',
      cancelText: '取消',
      onOk: () => {
        onBack();
      },
    });
  });

  const onSubmit = useMemoizedFn(async () => {
    const formInstance = formRef?.current?.getFormInstance();
    const value = await formInstance?.submit();
    console.log('========= value  :  ', value);
  });

  const effects = useMemoizedFn(() => {
    onFormMount(() => {
      console.log('======== 初始化');
    });
  });

  return (
    <div>
      <PageHeaderBar title={`${!examId ? '新建' : '编辑'}考试`} onBack={handleBack} />
      <Form
        ref={formRef}
        schema={schema}
        components={{
          Input,
          FormCard,
          DatePicker,
          NumberPicker,
          Radio,
          Select,
          PaperSelect,
        }}
        className="mb-4"
        hideAction
        layout={{ layout: 'vertical' }}
        formOptions={{
          effects,
        }}
      />
      <Button onClick={onSubmit}>测试校验</Button>
    </div>
  );
}
