import type { TableColumnType } from 'antd';
import dayjs from 'dayjs';

export const columns: TableColumnType[] = [
  {
    title: '试卷名称',
    key: 'title',
    width: 200,
    render: (row: any) =>
      row?.title ? <div className="text-[#338aff] cursor-pointer">{row?.title}</div> : '--',
  },
  {
    title: '分值',
    key: 'score',
    width: 60,
    dataIndex: 'score',
  },
  {
    title: '题目数',
    key: 'question_num',
    width: 100,
    dataIndex: 'question_num',
  },
];

export const selectColumns: TableColumnType[] = [
  {
    title: '试卷名称',
    key: 'title',
    width: 240,
    render: (row: any) => <div className="cursor-pointer text-[#338aff]">{row.title}</div>,
  },
  {
    title: '分值',
    key: 'score',
    width: 100,
    dataIndex: 'score',
  },
  {
    title: '题目数',
    key: 'question_num',
    width: 100,
    dataIndex: 'question_num',
  },
  {
    title: '创建时间',
    key: 'create_time',
    width: 200,
    render: (row: any) =>
      row.create_time ? dayjs(row.create_time).format('YYYY-MM-DD HH:mm') : '--',
  },
];
