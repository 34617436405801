import { useState } from 'react';
import { Table, Typography, Modal, Input, Button, Pagination } from 'antd';
import { FormItem } from '@formily/antd-v5';
import { AiOutlinePlus } from 'react-icons/ai';
import { connect, mapProps } from '@formily/react';
import { Field } from '@formily/core';
import { columns, selectColumns } from './config';
import { useBoolean, useMemoizedFn, useRequest } from 'ahooks';

const { Link } = Typography;

interface IProps {
  value: { paperId: string; paperVersionId: string };
  onChange?: (data?: { paperId: string; paperVersionId: string }) => void;
  errorText?: string;
}

function BasePaperSelect(props: IProps) {
  const { onChange, errorText } = props;
  const [selected, setSelected] = useState<string[]>([]);
  const [visible, { setTrue: setVisibleTrue, setFalse: setVisibleFalse }] = useBoolean(false);

  const [search, setSearch] = useState('');
  const [total, setTotal] = useState(0);
  const [selectData, setSelectData] = useState<any[]>([]);
  const [pageInfo, setPageInfo] = useState({
    current: 1,
    pageSize: 100,
  });

  const onCancelHandle = useMemoizedFn(() => {
    setVisibleFalse();
    setPageInfo({
      current: 1,
      pageSize: 10,
    });
    setTotal(0);
    setSelected([]);
    setSearch('');
  });

  const { data, loading } = useRequest(
    async () => {
      if (!visible) {
        return [];
      }
      return [
        {
          paperId: '123123123',
          question_num: 10,
          score: 100,
          create_time: '',
          title: '题目',
          paperVersionId: '12312',
        },
      ];
    },
    {
      refreshDeps: [visible, search, pageInfo],
    },
  );

  const handleOk = useMemoizedFn(() => {
    // 通过rowSelect 找到对应的试卷Id
    const resultData = selected
      ?.filter(Boolean)
      ?.map((item) => data?.find((i) => i.paperId === item));
    if (!resultData?.[0]) {
      return;
    }
    setSelectData(resultData);
    const { paperId, paperVersionId } = resultData?.[0] || {};
    onCancelHandle();

    onChange?.({
      paperId,
      paperVersionId,
    });
  });

  const getColumns = useMemoizedFn(() => {
    return [
      ...columns,
      {
        title: '操作',
        key: 'operate',
        width: 100,
        dataIndex: 'operate',
        render: () => {
          return (
            <div className="flex gap-2">
              <Link
                onClick={() => {
                  onChange?.(undefined);
                  setSelectData([]);
                }}>
                删除
              </Link>
            </div>
          );
        },
      },
    ];
  });
  return (
    <>
      <FormItem
        colon={false}
        label="添加试卷"
        asterisk
        labelCol={3}
        feedbackStatus="error"
        className="relative"
        feedbackText={errorText}>
        <Link
          className="absolute right-0 top-0"
          onClick={() => {
            setVisibleTrue();
          }}>
          <AiOutlinePlus /> 添加试卷
        </Link>
        <Table
          size="small"
          bordered
          dataSource={selectData}
          columns={getColumns()}
          locale={{
            emptyText: '暂无试卷',
          }}
        />
      </FormItem>
      <Modal
        destroyOnClose
        open={visible}
        title="选择试卷"
        onCancel={onCancelHandle}
        width="900px"
        footer={() => (
          <div className="flex justify-between items-center">
            <Pagination
              total={total}
              current={pageInfo?.current}
              pageSize={pageInfo?.pageSize}
              onChange={(page, pageSize) => {
                setPageInfo({
                  current: page,
                  pageSize,
                });
              }}
            />
            <div className="flex gap-2">
              <Button onClick={onCancelHandle}>取消</Button>
              <Button type="primary" disabled={!selected.length} onClick={handleOk}>
                保存
              </Button>
            </div>
          </div>
        )}>
        <div className="w-1/3 mb-4">
          <Input.Search
            value={search}
            placeholder="请输入试卷名称"
            onChange={(e) => setSearch(e.target?.value)}
          />
        </div>
        <Table
          key="paperId"
          rowKey="paperId"
          dataSource={data}
          size="small"
          columns={selectColumns}
          scroll={{ y: 600 }}
          pagination={false}
          rowSelection={{
            type: 'radio',
            selectedRowKeys: selected,
            onChange: (val) => setSelected(val as any[]),
          }}
          loading={loading}
        />
      </Modal>
    </>
  );
}

export const PaperSelect = connect(
  BasePaperSelect,
  mapProps((props, field) => {
    return {
      ...(props as IProps),
      value: (field as Field).value,
      onChange: (field as Field).onInput,
      errorText: (field as Field)?.errors?.[0]?.messages?.[0],
    };
  }),
);
